import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

// component
import ProductSingle from "./ProductSingle";

// portal navbar
import PortalNavbar from "../PortalNavbar";
import { connect } from "react-redux";
import { getPublicAllServices } from "../../../store/actions/publicActions";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import SearchFilter from "../../../shared/SearchFilter";
import { FILTER_OPTIONS, PAGINATION_LIMIT } from "../../../common/constants";
import ActivityLoader from "../../../shared/ActivityLoader/ActivityLoader";
import { LABELS } from "../../../common/label";
import useScrollToTop from "../../../common/hooks/useScrollToTop";

const Products = ({
  profile,
  getPublicAllServices,
  loading,
  services,
  backendAssetUrl,
  totalService,
  loadingMore,
  hasMore,
  categoryName,
}) => {
  const [order, setOrder] = useState(1);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState("LATEST");
  const [page, setPage] = useState(1);
  let { username } = useParams();
  useEffect(() => {
    getPublicAllServices({
      pageNumber: 1,
      limit: PAGINATION_LIMIT,
      sortType: "LATEST",
      search: "",
    });
  }, [getPublicAllServices]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search || search !== null) {
        getPublicAllServices(
          {
            search,
            pageNumber: 1,
            limit: PAGINATION_LIMIT,
            sortType: "LATEST",
          },
          1
        );
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, getPublicAllServices]);

  // add class before min width 992  start
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // add class before min width 992  end

  const handleFilter = (e) => {
    setFilter();
    setFilter(e.target.value);
    fetchRecords({
      sortType: e.target.value,
      pageNumber: 1,
    });
    setPage(1);
  };
  const fetchRecords = (payload) => {
    getPublicAllServices({
      pageNumber: page,
      limit: PAGINATION_LIMIT,
      sortType: filter,
      search: search ?? "",
      ...payload,
    });
  };

  const loadMore = () => {
    setPage((prevState) => prevState + 1);
    fetchRecords({
      pageNumber: page + 1,
    });
  };
  useScrollToTop();

  return (
    <div className={isMobile ? "" : "container"}>
    <div className={`portal_page_spacing our_product ${categoryName}`} >
      {loading ? <ActivityIndigator /> : null}
      <PortalNavbar
        title={LABELS.ALL_SERVICE_HEADER[categoryName] || ""}
        backgroundColor="#D6EFFF"
        backArrow="arrow_back"
      />
    <div className="desktop_sect_width desktop_inner_page">
    {profile?.businessInfo?.logo && (
        <section className="portal_logo">
          <img
            src={profile?.businessInfo?.logo}
            alt={profile?.businessInfo?.title}
          />
        </section>
      )}
      <section className="about_portal">
        <div className="head text-center">
          <h5 className="m22 blue">{profile?.businessInfo?.title || ""}</h5>
        </div>
        <div className="main_body">
          <h6 className="r14 lh22 textarea_text">{profile?.businessInfo?.bio || ""}</h6>
        </div>
      </section>
    </div>
      <section className="our_product sect_padding">
       <div className="desktop_sect_width">
       <h3 className="sect_title text-center">
          {LABELS.HOME_LIMITED_SERVICE[categoryName] || ""}
        </h3>
        <div className="row">
          <div className="col-8">
            <SearchFilter setSearch={setSearch} search={search} />
          </div>
          <div className="col-4 pl0">
            <div className="form_field">
              <select name="order" onChange={handleFilter}>
                {FILTER_OPTIONS.map((f) => (
                  <option value={f.value} key={f.id}>
                    {f.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="vg8"></div>
       </div>
        <div className="products_parent">
          {services.length > 0 ? (
            services.map((service) => (
              <ProductSingle
                key={service?._id}
                service={service}
                backendAssetUrl={backendAssetUrl}
                username={username}
              />
            ))
          ) : (
            <div>No search result found!</div>
          )}
        </div>
        <div className="vg22"></div>

        {totalService > PAGINATION_LIMIT && hasMore && (
          <button type="button" className="theme_btn desktop_btn" onClick={loadMore}>
            {loadingMore ? <ActivityLoader /> : "Load More"}
          </button>
        )}
      </section>
    </div></div>
  );
};
const mapStateToProps = ({
  profile: {
    services,
    loading,
    backendAssetUrl,
    totalService,
    loadingMore,
    hasMore,
  },
}) => {
  return {
    loading,
    services,
    backendAssetUrl,
    totalService,
    loadingMore,
    hasMore,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPublicAllServices: (payload) => dispatch(getPublicAllServices(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
